/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #595959;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #595959;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header-wrap {
  background: #f5f5f5;
}
#header {
  a.logo {
    float: left;
    border: 0;
    width: 75%;
    max-width: 480px; /* change this to the width of the actual logo */
    margin-top: 20px;
    margin-bottom: 20px;
    img {
      margin-right: 15px;
      width: auto;
      height: auto;
      max-width: 80px;
    }
    span {
      display: inline-block;
      margin-top: 3px;
      font-size: 21px;
      line-height: 1.2;
      vertical-align: middle;
      font-family: 'Roboto Slab', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica;
      color: #444;
    }
    .small {
      display: none;
      margin-top: 0;
      font-size: 18px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      margin: 0 10px;
      font-weight: normal;
      color: #555;
      border: 0 !important;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
  }
}

#banner-wrap {
  background: #000;
  #banner {
    background: url(../../../images/fl-tattoo-banner-sm.jpg) no-repeat 50% 50%;
    background-size: cover;
    ul {
      font-size: 1.1em;
      font-weight: 600;
    }
  }
}

#points-wrap {
  background: #f5f5f5;
  .circle-icon {
    height: 60px;
    width: 60px;
    min-width: 60px;
    padding: 5px;
    border-radius: 50.1%;
    border: 2px solid #555;
    display: flex;
    flex-direction: column;
    place-items: center;
  }
}

.card.red {
  border: 1px $link-color solid;
}

#cta-wrap {
  background-color: #f5f5f5;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo span {
    font-size: 26px;
    line-height: 1;
  }
  #header a.logo .small {
    display: inline-block;
    font-size: 16px;
  }
  #header a.logo span.big br {
    display: none;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding: 0 30px;
  }
  #header a.logo img {
    display: inline-block;
  }
  #points-wrap {
    .circle-icon {
      margin: 0 auto;
      height: 80px;
      width: 80px;
      padding: 10px;
    }
  }
}
// roughly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header a.logo {
    max-width: 600px;
  }
  #header #support {
    span.phone {
      display: none;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
  #points-wrap {
    .circle-icon {
      height: 120px;
      width: 120px;
      img {
        height: 70px;
        width: 85px;
      }
    }
  }
  #footer p.trustmark {
    display: block;
    margin: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 64%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 560px;
  }
  #banner-wrap {
    background: #000;
    #banner {
      background: transparent url(../images/fl-tattoo-banner-lg.jpg) no-repeat 50% 25% / cover;
      p {
        font-size: x-large;
      }
    }
  }
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
